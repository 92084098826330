<template>
  <div v-if="canAccess('empleados_read')" class="card">
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" />
    <ProgressBar mode="indeterminate" v-if="processing" />
    <div class="p-col-12 p-pt-4">
      <div class="p-grid p-fluid employee-list-search-inputs">
        <div
            class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2 p-form-field"
            v-if="isAdmin"
        >
          <span class="p-float-label">
            <Dropdown
                v-model="selectedCountry"
                :options="countries"
                optionLabel="country"
                @change="
                selectSubsidiary = null;
                filter();
              "
            >
              <template #option="slotProps">
                <div class="autocomplete-value">
                  {{ slotProps.option.country }}
                </div>
              </template>
            </Dropdown>
            <label for="country">País</label>
          </span>
        </div>
        <div
            class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2 p-form-field"
        >
          <span class="p-float-label">
            <Dropdown
                v-model="selectSubsidiary"
                :options="subsidiaries"
                optionLabel="name"
                @change="filter()"
            >
              <template #option="slotProps">
                <div class="autocomplete-value">
                  {{ slotProps.option.name }}
                </div>
              </template>
            </Dropdown>
            <label for="months">Filial</label>
          </span>
        </div>
        <div
            class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2 p-form-field"
        >
          <span class="p-float-label">
            <Calendar
                id="start_date"
                :showIcon="true"
                v-model="from"
                dateFormat="yy-mm-dd"
                :yearNavigator="true"
                yearRange="2018:2050"
                class="p-inputtext-sm"
                @date-select="filter()"
            />
            <label for="start_date">Desde</label>
          </span>
        </div>
        <div
            class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2 p-form-field"
        >
          <span class="p-float-label">
            <Calendar
                id="end_date"
                :showIcon="true"
                v-model="to"
                dateFormat="yy-mm-dd"
                :yearNavigator="true"
                yearRange="2018:2050"
                class="p-inputtext-sm"
                @date-select="filter()"
            />
            <label for="end_date">Hasta</label>
          </span>
        </div>
        <div
            class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3 p-xl-2 p-form-field"
        >
          <span class="p-input-icon-right p-float-label">
            <i class="pi pi-search" />
            <InputText v-model="search" @keypress.enter="filter" />
            <label class="" for="end_date">Buscar</label>
          </span>
        </div>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-field p-fluid p-col-fixed">
          <Button
              v-if="canAccess('empleados_create')"
              label="Agregar"
              icon="pi pi-plus"
              class="p-button-success"
              @click="$router.push({ name: 'employeeCrud' })"
          />
        </div>
        <div class="p-field p-fluid p-col-fixed">
          <Button
              label="Limpiar"
              icon="pi pi-undo"
              class="p-button-secondary"
              @click="reload"
          />
        </div>
      </div>
    </div>
    <DataTable
        :value="employeeList"
        :filters="filters"
        class="p-datatable-responsive-demo p-datatable-sm"
        v-model:selection="currentEmployee"
        selectionMode="single"
        dataKey="id"
        :paginator="true"
        paginatorPosition="bottom"
        :lazy="true"
        :totalRecords="page.total"
        :rows="page.per_page"
        :loading="loading"
        @page="onPage($event)"
    >
      <template #header>
        <div class="table-header">
          <h5 class="p-m-0">Lista de empleados</h5>
        </div>
      </template>
      <Column field="id" header="#" :sortable="true" headerStyle="width: 4em">
        <template #body="slotProps">
          <span class="p-column-title">#</span>
          <span class="p-column-value">
            {{ slotProps.data.id }}
          </span>
        </template>
      </Column>
      <Column
          field="dni"
          header="Cédula"
          :sortable="true"
          headerStyle="width: 10%"
      >
        <template #body="slotProps">
          <span class="p-column-title">Cédula</span>
          <span class="p-column-value">
            {{ slotProps.data.dni }}
          </span>
        </template>
      </Column>
      <Column field="name" header="Nombre" :sortable="true">
        <template #body="slotProps">
          <span class="p-column-title">Nombre</span>
          <span class="p-column-value">
            {{ slotProps.data.name }}
          </span>
        </template>
      </Column>
      <Column
          field="email"
          header="mail"
          :sortable="true"
          headerStyle="width: 20%;"
      >
        <template #body="slotProps">
          <span class="p-column-title">Mail</span>
          <span class="p-column-value">
            {{ slotProps.data.email }}
          </span>
        </template>
      </Column>
      <Column field="position" header="Cargo" :sortable="true">
        <template #body="slotProps">
          <span class="p-column-title">Cargo</span>
          <span class="p-column-value">
            {{ slotProps.data.position }}
          </span>
        </template>
      </Column>
      <Column
          field="subsidiary"
          header="Filial"
          :sortable="true"
          headerStyle="width: auto"
      >
        <template #body="slotProps">
          <span class="p-column-title">Filial</span>
          <span class="p-column-value">
            {{ slotProps.data.subsidiary }}
          </span>
        </template>
      </Column>
      <Column
          field="company"
          header="Empresa"
          :sortable="true"
          headerStyle="width: auto"
      >
        <template #body="slotProps">
          <span class="p-column-title">Empresa</span>
          <span class="p-column-value">
            {{ slotProps.data.company }}
          </span>
        </template>
      </Column>
      <Column header="Opciones" headerStyle="width: 9.1rem">
        <template #body="slotProps">
          <span class="p-column-title">Opciones</span>
          <Button
              icon="pi pi-lock"
              class="p-button-rounded p-button-info p-mr-2"
              v-if="isAdmin"
              @click="(currentEmployee = slotProps.data), (pswDialog = true)"
          />
          <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success p-mr-2"
              @click="edit(slotProps.data)"
              v-if="canAccess('empleados_update')"
          />
          <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning"
              @click="confirmDelete(slotProps.data)"
              v-if="canAccess('empleados_delete')"
          />
        </template>
      </Column>
    </DataTable>
    <Dialog
        v-model:visible="pswDialog"
        header="Cambiar password"
        :modal="true"
        v-if="isAdmin"
        class="p-fluid p-col-fixed"
    >
      <div class="p-field p-fluid p-col-12 p-mt-4" v-if="currentEmployee">
        <span class="p-float-label">
          <InputText
              type="password"
              id="password"
              v-model.trim="password.password"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !password.password }"
          />
          <label for="password">Password</label>
        </span>
        <small class="p-invalid" v-if="submitted && !password.password">
          El password es requerido
        </small>
      </div>
      <div class="p-field p-fluid p-col-12" v-if="currentEmployee">
        <span class="p-float-label">
          <InputText
              type="password"
              id="password_c"
              v-model.trim="password.password_confirmation"
              required="true"
              autofocus
              :class="{
              'p-invalid': submitted && !password.password_confirmation,
            }"
          />
          <label for="password_c">Password</label>
        </span>
        <small
            class="p-invalid"
            v-if="submitted && !password.password_confirmation"
        >
          La confirmación es requerido
        </small>
      </div>
      <template #footer>
        <div class="p-mt-1">
          <Button
              label="Cancel"
              icon="pi pi-times"
              class="p-button-text"
              @click="(pswDialog = false), (password = {})"
          />
          <Button
              label="Save"
              icon="pi pi-check"
              class="p-button-text"
              @click="savePassword"
          />
        </div>
      </template>
    </Dialog>
    <Dialog
        v-model:visible="confirmDeleteModal"
        header="Confirm"
        :modal="true"
        v-if="canAccess('empleados_delete')"
        class="p-fluid p-field p-col-fixed"
    >
      <div class="confirmation-content p-mt-4">
        <i class="pi pi-exclamation-triangle size-icon" />
        <span v-if="currentEmployee">Seguro de eliminar registro?</span>
      </div>
      <template #footer>
        <div class="p-mt-1">
          <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="confirmDeleteModal = false"
          />
          <Button
              label="Si"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteRow"
          />
        </div>
      </template>
    </Dialog>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>¡ATENCIÓN!</b></h5>
    <div class="card-body">
      <p class="card-title">
        El acceso a esta opción no se encuentra autorizado
      </p>
      <Button
          label="Regresar"
          class="p-button-danger p-mr-2"
          @click="$router.push('/admin')"
      />
    </div>
  </div>
</template>
<script src="./EmployeeList.js" lang="js"></script>
<style src="./EmployeeList.scss" lang="scss" scoped></style>
